<template>
<div class="step">
    <div class="step-items">
        <div class="step-item" v-for="i in 4" :key="i" :class="i<=input?'step-item-active':i==input?'active-step-text step-item-active':''">
            <div class="step-line"></div>
            <div class="step-text" @click="input=i" :class="i==input?'active-step-text':''"> {{i}} </div>
        </div>

    </div>
</div>
</template>

<script>
 export default {
     props: ['value'],
     data() {
         return {
             input: 0
         }
     },
     watch: {
         value: {
             handler(after) {
                 this.input = after;
             },
             immediate: true,
         },
         input(){  
            this.$emit('input', this.input);
         }
     },
 }
</script>

<style lang="scss">
.step-items {
    display: flex;
    align-items: center;
}

.step-item {
    display: flex;
    align-items: center;

}

.step-line {
    width: 160px;
    height: 6px;
    background: #C4C4C4;

}

.step-line:nth-child(1) {
    width: 220px;
}

.step-text {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);
    background: #C4C4C4;
    color: var(--white-color);
    border-radius: 50%;
    font-family: Stem-Medium;
    font-size: 20px;
    line-height: 1.5rem;
    cursor: pointer;
}

.step-item-active {
    .step-line {
        background: #00B5EC;
    }

    .step-text {
        background: #00B5EC;
    }
}

@media screen and(max-width:1100px) {
    .step-line {
        width: 90px;
    }

    .step-line:nth-child(1) {
        width: 120px;
    }

}

@media screen and(max-width:768px) {
    .step-line {
        width: 40px;
        height: 4px;
    }

    .step-line:nth-child(1) {
        width: 50px;
    }

    .step-text {
        width: 30px;
        height: 30px;
        font-size: 0.875rem;
        line-height: 1rem;
    }

    .active-step-text {
        width: 40px;
        height: 40px;
    }

}

@media screen and(max-width:368px) {
    .step-line {
        width: 20px;
        height: 4px;
    }

    .step-line:nth-child(1) {
        width: 30px;
    }

}
</style>
