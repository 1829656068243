<template>
<div class="flex justify-center  w-full white-bg">
    <div class="credit-view owf-h mw-1920 w-full">
        <div class="credit-top-view pt-200">
            <h1 class="top-h1" data-aos="fade-up" v-if="!loading"> {{Credit.data[`title_${$i18n.locale}`]}} </h1>
            <p class="top-p mt-20px" data-aos="fade-up" v-if="!loading"> {{Credit.data[`description_${$i18n.locale}`]}} </p>
            <el-skeleton v-if="loading" />
            <!-- <div class="credit-top-bg" v-if="Credit.image">
                <img src="@/assets/img/stockbg.svg" alt="">
            </div>
            <div class="credit-top-bg" v-else>
                <img src="@/assets/img/stockbg.svg" alt="">
            </div> -->
        </div>
        <div class="steps">
            <Step v-model="activeName" />
        </div>
        <div class="flex justify-center fw-w" v-if="activeName==1">
            <div class="credit-count pt-0 pb-0 mw-1920 w-full">
                <div class="credit-count-items">
                    <div class="header-cover">
                        <div class="credit-header">
                            <div class="credit-header-left" :class="kredit_type=='pledge'?'credit-header-active':''" @click="kredit_type='pledge'">
                                {{Calculator.data[`type1_${$i18n.locale}`]}}
                            </div>
                            <div class="credit-header-right" :class="kredit_type=='collateral'?'credit-header-active':''" @click="kredit_type='collateral'">
                                {{Calculator.data[`type2_${$i18n.locale}`]}}
                            </div>
                        </div>
                    </div>
                    <div class="credit-item">
                        <div class="credit-item-left">
                            <div class="credit-slider" data-aos="fade-up">
                                <div class="credit-slider-top-text">
                                    <h3>{{Calculator.data[`count_${$i18n.locale}`]}}</h3>
                                    <div class="credit-input">
                                        <input type="number" onkeypress="this.style.width = (this.value.length) + 'em';" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" :minlength="Calculator.data.min_sum.length" :maxlength="Calculator.data.max_sum.length" class="input" :class="disabled?'erorr':''" @keypress="Valid($event)" v-model="creditCount.count" />
                                        <p :class="disabled?'erorr':''"> ₴</p>
                                    </div>
                                </div>
                                <RangeSlider :max="Calculator.data.max_sum" :setp="0.1*Calculator.data.min_sum" :min="Calculator.data.min_sum" v-model="creditCount.count" />
                                <div class="credit-slider-bottom-text">
                                    <p>{{Calculator.data.min_sum}} ₴</p>
                                    <p>{{Calculator.data.max_sum}} ₴</p>
                                </div>
                            </div>
                            <div class="credit-slider" data-aos="fade-up">
                                <div class="credit-slider-top-text">
                                    <h3>{{Calculator.data[`term_${$i18n.locale}`]}}</h3>
                                    <p>{{creditCount.month}} {{Calculator.data[`month_${$i18n.locale}`]}}</p>
                                </div>
                                <RangeSlider :max="Calculator.data.max_month" :min="Calculator.data.min_month" :setp="1" v-model="creditCount.month" />
                                <div class="credit-slider-bottom-text">
                                    <p> {{Calculator.data.min_month}} {{Calculator.data[`month_${$i18n.locale}`]}}</p>
                                    <p>{{Calculator.data.max_month}} {{Calculator.data[`month_${$i18n.locale}`]}}</p>
                                </div>
                            </div>

                        </div>
                        <div class="credit-item-right">
                            <div class="credit-right-card">
                                <div class="credit-right-card-header">
                                    <p>{{Calculator.data[`count_${$i18n.locale}`]}}</p>
                                    <p>{{creditCount.count}} грн</p>

                                </div>
                                <div class="credit-right-card-body">
                                    <div class="body_item">
                                        <p>{{Calculator.data[`accrual_${$i18n.locale}`]}}</p>
                                        <p> {{creditCount.accrual}} грн</p>
                                    </div>
                                    <div class="body_item">
                                        <p>{{Calculator.data[`redemption_${$i18n.locale}`]}}</p>
                                        <p>{{creditCount.redemption}} грн</p>
                                    </div>
                                    <div class="body_item">
                                        <p>{{Calculator.data[`turning_${$i18n.locale}`]}}</p>
                                        <p>{{creditCount.lastDate}}</p>
                                    </div>
                                    <div class="body_item">
                                        <p>{{Calculator.data[`percent_${$i18n.locale}`]}}</p>
                                        <p>{{creditCount.percenti}} %</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full flex justify-center credit-next">
                <button class="button"  :disabled="disabled" @click="activeName=2" :class="disabled?'disabled_button':''"> {{$t('continue')}} </button>
            </div>
        </div>
        <div class="flex justify-center" v-if="activeName==2">
            <div class="credit-info w-full">
                <div class="credit-info-texts">
                    <h1 data-aos="fade-right">{{Credit.data.step2[`title_${$i18n.locale}`]}}</h1>
                    <p data-aos="fade-left">{{Credit.data.step2[`description_${$i18n.locale}`]}}</p>
                    <div class="flex fw-w mt-20">
                        <a :href="Credit.data.step2.link1" class="mr-20">
                            <button class="button gray  mt-20"> {{Credit.data.step2[`button1_${$i18n.locale}`]}} </button>
                        </a>
                        <a :href="Credit.data.step2.link2">
                            <button class="button blue mt-20"> {{Credit.data.step2[`button2_${$i18n.locale}`]}} </button>
                        </a>
                    </div>
                </div>
                <div class="cretit-table-top">
                    <p data-aos="fade-left"> <span> {{$t('Summa')}} :</span> {{creditCount.count}} грн </p>
                    <p data-aos="fade-left"> <span> {{$t('accrual')}} </span> {{creditCount.accrual}} грн </p>
                    <p data-aos="fade-left"> <span> {{$t('redemption')}} </span> {{creditCount.redemption}} грн </p>
                </div>
                <div class="credit-table desktop">
                    <h1> {{$t('plan_oplati')}} </h1>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>№</th>
                                <th> {{$t('date')}} </th>
                                <th> {{$t('do_credit')}} </th>
                                <th> {{$t('leftovers')}} </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,i) in monthlyPayTable" :key="i">
                                <td class="credit-table-hide-td"></td>
                                <td>{{i+1}}</td>
                                <td> {{item.date}} </td>
                                <td> {{item.amount}} грн</td>
                                <td class="c-blue"> {{item.leftover}} грн</td>
                                <td class="credit-table-hide-td"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="credit-table-phone phone">
                    <h1> {{$t('plan_oplati')}} </h1>
                    <div class="credit-table-phone-tr " v-for="(item,i) in monthlyPayTable" :key="i" :class="i%2==0?'bg-gray':''" :data-aos="i%2==1?'fade-right':'fade-left'">
                        <div class="credit-table-phone-td">
                            <p class="credit-table-phone-td-head">№</p>
                            <p>{{i+1}}</p>
                        </div>
                        <div class="credit-table-phone-td">
                            <p class="credit-table-phone-td-head"> {{$t('date')}} </p>
                            <p>{{item.date}}</p>
                        </div>
                        <div class="credit-table-phone-td">
                            <p class="credit-table-phone-td-head"> {{$t('do_credit')}} </p>
                            <p>{{item.amount}} грн</p>
                        </div>
                        <div class="credit-table-phone-td">
                            <p class="credit-table-phone-td-head"> {{$t('leftovers')}} </p>
                            <p class="c-blue">{{item.leftover}} грн</p>
                        </div>
                    </div>
                </div>
                <div class="w-full flex justify-center credit-next-button">
                    <button class="button" @click="Check()"> {{$t('continue')}} </button>
                </div>
            </div>

        </div>
        <div class="flex justify-center" v-if="activeName==3">
            <div class="credit-input mw-1920">
                <div class="credit-input-texts">
                    <h1 data-aos="fade-up"> {{$t('info_pro_credit')}} </h1>
                    <p class="c-blue" data-aos="fade-up"> {{$t('fiz_osoba')}} </p>
                </div>

                <div class="credit-input-items">
                    <div class="credit-input-item" data-aos="fade-up">
                        <input type="text" placeholder="Петр">
                        <input type="text" placeholder="Петров">
                        <input type="text" placeholder="Петрович">
                        <input type="text" placeholder="Email">
                        <input type="text" placeholder="+ 380 099 99 99">
                        <input type="text" placeholder="20/10/2020">
                    </div>
                    <div class="credit-input-item" data-aos="fade-up">
                        <input type="text" placeholder="JJ8588585">
                        <input type="text" placeholder="Ким виданий паспорт">
                        <input type="text" placeholder="17/02/12">
                        <input type="text" placeholder="Ідентифікаційний номер">
                        <input type="text" placeholder="Київська область, місто Київ, вулиця Київська">
                        <input type="text" placeholder="Будинок 8, квартира 15">
                    </div>
                </div>
                <div class="w-full flex justify-center credit-next-button">
                    <button class="button" @click="activeName=4; ScrollTop()"> {{$t('continue')}} </button>
                </div>
            </div>
        </div>
        <div class="flex justify-center" v-if="activeName==4">
            <div class="credit-info-last mw-1920">

                <div class="credit-last-items">
                    <div class="credit-last-item">
                        <div class="credit-last-texts">
                            <h1 data-aos="fade-right">{{Credit.data.step4[`title_${$i18n.locale}`]}}</h1>
                            <p data-aos="fade-right">{{Credit.data.step4[`description_${$i18n.locale}`]}}</p>
                        </div>
                        <div class="credit-last-phone">
                            <h1 data-aos="fade-right">{{Credit.data.step4.phone}}</h1>
                            <p data-aos="fade-right">{{Credit.data.step4[`phone_title_${$i18n.locale}`]}}</p>
                        </div>
                        <div class="credit-last-email">
                            <p data-aos="fade-right">{{$t('support_service')}}  <span>{{Credit.data.step4.mail1}}</span> </p>
                            <p data-aos="fade-right">{{$t('support_service')}} <span>{{Credit.data.step4.mail2}}</span> </p>
                            <p data-aos="fade-right">{{$t('support_service')}} <span>{{Credit.data.step4.mail3}}</span> </p>
                        </div>
                        <div class="credit-last-addres">
                            <p data-aos="fade-right"> {{Credit.data.step4[`adress_${$i18n.locale}`]}} </p>
                        </div>
                    </div>
                    <div class="credit-last-item">
                        <div class="credit-last-img">
                            <img :src="Credit.files.step4Image? `https://avior.prestigeautofl.com${Credit.files.step4Image}`: require(`@/assets/img/big-a.svg`)" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import RangeSlider from "../components/sliderrange.vue"
import Step from '../components/steps.vue'
export default {
    name: 'credit',
    components: {
        RangeSlider,
        Step
    },
    data() {
        return {
            a: 10,
            kredit_type: 'pledge',
            loading: false,
            activeName: 1,
            credit_percent_year: 10,
            credit_percent_month: 10,
            CreditInputs: {
                first_name: "Петр",
                last_name: 'Петров',
                mid_name: 'Петрович',
                email: 'Email',
                phone: '+ 380 099 99 99',
                date: '20/10/2020',
                passport: 'JJ8588585',
                passport_from: 'Ким виданий паспорт',
                passpodt_date: '17/02/12',
                id_number: 'Ідентифікаційний номер',
                district: 'Київська область, місто Київ, вулиця Київська',
                adress: 'Будинок 8, квартира 15'
            },
            creditCount: {
                count: 1000,
                accrual: 0,
                redemption: 0,
                month: 1,
                lastDate: '--.--.--',
                percenti: 0

            },
            monthlyPayTable: [],
            disabled: false

        }
    },
    computed: {
        Credit() {
            return this.$store.state.Credit
        },
        Calculator() {
            return this.$store.state.Calculator
        },
        me() {
            return this.$store.state.me
        }
    },

    methods: {
        ScrollTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        },
        Check() {
            if (this.me.id) {
                this.activeName = 3;
                this.ScrollTop()
            } else {
                this.$router.push('/login');
                localStorage.count = this.creditCount.count
                localStorage.month = this.creditCount.month
                localStorage.type = 'credit'
            }

        },
        Valid(event) {
            if (parseFloat(this.creditCount.count) > parseFloat(this.Calculator.data.max_sum)) {
                this.creditCount.count = this.Calculator.data.max_sum
            }
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 48 || keyCode > 57)) { 
                event.preventDefault();
            }

        },

        countDate(month) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            mm = parseInt(mm)
            yyyy = parseInt(yyyy)
            var yyyy = today.getFullYear();
            var lastyear = parseInt(month / 12)
            var lastmonth = month - lastyear * 12
            yyyy = yyyy + parseInt((mm + lastmonth) / 12) + lastyear
            mm = (mm + lastmonth) % 12
            mm = mm == 0 ? '01' : mm < 10 ? '0' + mm : mm
            this.creditCount.lastDate = dd + '.' + mm + '.' + yyyy;

        },
        countCredit(cost, month) {

            if (month > 11) {
                this.creditCount.percenti = parseInt(this.credit_percent_year)
            } else {
                this.creditCount.percenti = parseInt(this.credit_percent_month)
            }
            var CostAccrual = 0

            for (let i = 1; i <= month; i++) {
                CostAccrual = parseFloat(CostAccrual) + (parseFloat(CostAccrual) + parseFloat(cost)) * parseInt(this.creditCount.percenti) / 100
            }
            CostAccrual = CostAccrual.toFixed(2)
            this.creditCount.redemption = (parseFloat(cost) + parseFloat(CostAccrual)).toFixed(2)
            this.creditCount.accrual = CostAccrual

            var CreditTable = []
            var creditMonthPay = parseFloat(this.creditCount.redemption / month).toFixed(2)

            for (let i = 1; i <= month; i++) {
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                mm = parseInt(mm)
                yyyy = parseInt(yyyy)
                var yyyy = today.getFullYear();
                var lastyear = parseInt(i / 12)
                var lastmonth = i - lastyear * 12
                yyyy = yyyy + parseInt((mm + lastmonth) / 12) + lastyear
                mm = (mm + lastmonth) % 12
                mm = mm == 0 ? '01' : mm < 10 ? '0' + mm : mm
                var date = dd + '.' + mm + '.' + yyyy;
                var Leftover = this.creditCount.redemption - creditMonthPay * i
                CreditTable.push({
                    date: date,
                    amount: parseFloat(creditMonthPay).toFixed(2),
                    leftover: parseFloat(Leftover).toFixed(2) >= 1 ? parseFloat(Leftover).toFixed(2) : 0
                })
            }
            this.monthlyPayTable = CreditTable

        },

    },
    watch: {
        'creditCount.month'(val) {
            this.countCredit(this.creditCount.count, val)
            this.countDate(val)
        },
        'creditCount.count'(val) {
            this.countCredit(val, this.creditCount.month)
            this.disabled = false

            let Num = /^[0-9]+$/
            if (!Num.test(val) || parseFloat(this.creditCount.count) < parseFloat(this.Calculator.data.min_sum)) {
                this.disabled = true
            }
        },
        Calculator() {
            this.creditCount.month = this.$route.query.month ? this.$route.query.month : this.Calculator && this.Calculator.data && this.Calculator.data.min_month ? this.Calculator.data.min_month : 1
            this.creditCount.count = this.$route.query.amount ? this.$route.query.amount : this.Calculator.data && this.Calculator.data.min_sum ? this.Calculator.data.min_sum : 1000
            this.credit_percent_year = this.Calculator && this.Calculator.data && this.Calculator.data.percent_year ? this.Calculator.data.percent_year : 10
            this.credit_percent_month = this.Calculator && this.Calculator.data && this.Calculator.data.percent_month ? this.Calculator.data.percent_month : 10
        }
    },
    mounted() {
        this.creditCount.count = this.$route.query.amount ? this.$route.query.amount : this.Calculator.data && this.Calculator.data.min_sum ? this.Calculator.data.min_sum : 1000
        this.creditCount.month = this.$route.query.month ? this.$route.query.month : this.Calculator && this.Calculator.data && this.Calculator.data.min_month ? this.Calculator.data.min_month : 1
        this.activeName=this.$route.query.step? this.$route.query.step:1
        this.countCredit(this.creditCount.count, this.creditCount.month)
        this.countDate(1)
        this.credit_percent_year = this.Calculator && this.Calculator.data && this.Calculator.data.percent_year ? this.Calculator.data.percent_year : 10
        this.credit_percent_month = this.Calculator && this.Calculator.data && this.Calculator.data.percent_month ? this.Calculator.data.percent_month : 10
    }

}
</script>
