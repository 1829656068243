<template>
<div class="range-slider">
    <input type="range" :step="setp" @input="updateInput" @change="updateInput" v-model="input" :max="max" :min="min" class="range-slider-input">
    <div class="range-slider-progress" :style="{width:`${width}%`}"></div>
</div>
</template>

<script>
export default {
    props: ['value', 'max', 'min', 'setp'],
    data() {
        return {
            input: 0,
            width: 0
        }
    },
    watch: {

        value: {

            handler(after) {
                this.input = after;
                this.width = parseInt(this.input) > parseInt(this.min) ? (parseInt(this.input) - parseInt(this.min)) * 100 / (parseInt(this.max) - parseInt(this.min)) : parseInt(0)
            },
            immediate: true,
        },
        input() {
            this.width = parseInt(this.input) > parseInt(this.min) ? (parseInt(this.input) - parseInt(this.min)) * 100 / (parseInt(this.max) - parseInt(this.min)) : parseInt(0)
        }
    },
    methods: {
        updateInput() {
            this.$emit('input', this.input);
            this.width = parseInt(this.input) > parseInt(this.min) ? (parseInt(this.input) - parseInt(this.min)) * 100 / (parseInt(this.max) - parseInt(this.min)) : parseInt(0)
        },
    }
}
</script>

<style lang="scss">
.range-slider {
    width: 100%;
    position: relative;
    overflow: hidden;
    min-height: 24px;
    display: flex;
    align-items: center;

}

.range-slider-input {
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 6px;
    border-radius: 3px;
    background: #F2F2F2;
    position: relative;
    z-index: 0;
    margin: 0;

}

.range-slider-input::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    background: #00B5EC;
    box-shadow: 0px 1px 4px rgba(111, 111, 111, 0.25);
}

.range-slider-input::-moz-range-thumb {
    -moz-appearance: none !important;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    background: #00B5EC;
    box-shadow: 0px 1px 4px rgba(111, 111, 111, 0.25);
}

.range-slider-progress {
    position: absolute;
    top: 8px;
    left: 0;
    height: 6px;
    width: 50%;
    background: #00B5EC;
    z-index: 1;
    border-radius: 3px;
}

@media screen and(max-width:768px) {
    .range-slider-input {

        background: rgba(158, 225, 246, 0.5);

    }

    .range-slider-progress {
        background: var(--white-bg);
    }

    .range-slider-input::-moz-range-thumb {
        background: var(--white-bg);
    }

    .range-slider-input::-webkit-slider-thumb {
        background: var(--white-bg);
    }

}
</style>
